.card {
  height: 120px;
}

.title {
  overflow: hidden;
  position: relative;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(transparent, white);
    pointer-events: none;
  }
}

.titleHeight {
  height: 65px;
}

.smallButton {
  padding: 5px !important;
  width: 20px;
  height: 20px;
  font-size: 14px;
  border: 1px solid var(--bs-secondary) !important;
  align-self: center;
}
.smallButton:hover {
  background-color: #ed2369 !important;
}

.buttonExitClass:hover{
  color: #fff !important;
}
