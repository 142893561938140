@import "/src/styles/variables";

.image {
  width: 100%;
  max-height: 850px;
  object-fit: cover;
  border-radius: 4px;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.02),
    0px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.04),
    0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 0;
}

.code {
  position: fixed;
  top: 0;
}

.accordion {
  width: 100%;
  padding: 0;
  border: none;
  box-shadow: none;

  & > button:focus {
    box-shadow: none;
  }
}

.accordionItem {
  padding: 0;
  border: none;
  box-shadow: none;
  --bs-accordion-border-color: none;
}

.accordionHeader {
  background-color: none;
  border: none;
  box-shadow: none;

  & > button {
    padding-left: 0;
    padding-right: 30px;
    border-color: red;

    &:focus {
      background-color: #fff;
      box-shadow: none;
    }

    &:not(.collapsed) {
      color: $dark;
      background-color: #fff;

      &::after:focus {
        background-image: none;
        box-shadow: none;
      }
    }
  }
}

.accordionBody {
  padding: 0;
  border: none;
  box-shadow: none;
}

.pdfContainer {
  position: absolute;
  left: -10000px;
  top: 0;
}

.radius {
  border-radius: 4px;
}

.infoPadding {
  padding-bottom: 24px;
  @media (max-width: 991px) {
    padding-bottom: 16px;
  }
}

.rowPadding {
  padding-bottom: 48px;
  @media (max-width: 991px) {
    padding-bottom: 32px;
  }
}

.colPadding {
  padding: 24px;
  @media (max-width: 991px) {
    padding: 24px 16px 20px;
  }
}

.html {
  a {
    color: #11c0f2 !important;
    font-weight: bold;
  }
}
.deleteButton:hover {
  color: #fff !important;
}
