// @import "/src/styles/variables";
$primary: #11c0f2;
$secondary: #ed2369;
$success: #28dfae;
$warning: #fe6f4f;
$dark: #003b50;
$dark-2: #00739c;
$gray: #e9ecef;
$light: #f8f9fa;
$background: #f1f1f1;
$disabled: #cccccc;
$natural-gray: #adb5bd;

.footer {
  background-color: $primary;
  color: #ffffff;
  padding: 34px 15px;
  font-size: 12px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  p {
    padding: 0;
    margin: 0;
  }
  a {
    color: #ffffff !important;
  }
}
.navList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 24px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  padding-top: 8px;
}
.listItem {
  max-width: 202px;
  width: 100%;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}
.companyInfo:nth-of-type(2) {
  margin-bottom: 17px;
}

.navItem {
 &:not(:last-child) {
   margin-bottom: 5px;
 }
}
.socialListItem {
  display: flex;
  justify-content: center;
  a {
    margin: 0 7px 24px;
  }
}
.aliviaLink {
  text-decoration: underline !important;
}

@media screen and (min-width: 991.98px) {
  .navList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 40x;
    row-gap: 1em;
    align-items: flex-start;
    justify-content: space-around;
    font-size: 13px;
  }
  .listItem {
    text-align: start;
    &:last-child  {
      text-align: end;
    }
  }

}
