@import "/src/styles/variables";
.title {
  padding: 16px 34px ;
}
.formContainer{
  padding-top: 50px;
}
.listItem {
  display: flex;
  padding-left: 15px;
}
.urlItem {
  display: flex;
  align-items: baseline;
  margin-right: 15px;
}
.urlLabel {
  margin-right: 15px !important;
}
.formLabel {
  width: 400px !important;
  margin:5px 10px 0px 0px  !important;
  padding: 15px !important;
  border: 1px solid white;
  border-radius: 3px;
}
.addBtn {
  border: 2px solid $primary ;
  border-radius: 50px;
  color: $primary;
  background: none;
  width: 38px !important;
  height: 38px;
  &:hover {
    background: $primary;
    color: white
  }
}



